import "./App.css";
import Header from "./components/Header/header";
import VideoLink from "./components/Videolink/videolink";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Images from "./pages/Images/Images"
import Advert from './pages/Advert/Advert'
import Channel from './pages/Channel/Channel'
import ScoreCard from './pages/ScoreCard/ScoreCard'
import ChannelManagement from "./pages/ChannelManagement/ChannelManagement";



function App() {

  return (
    <div className="App">
      <Router>
        <Header/>
        <Routes>
          <Route exact path="/" element={<VideoLink/>}></Route>
          <Route path="/logo" element={<Images />} />
          <Route path="/advert" element={<Advert />} />
          <Route path="/channel" element={<Channel />} />
          <Route path="/scorecard" element={<ScoreCard />} />
          <Route path="/manage" element={<ChannelManagement />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
