import { Link } from "react-router-dom";
import style from "./header.module.css";
const Header = () => {
  return (
    <>
      <header>
        <div className="col-md-12 text-center">
            <nav>
          <div className={style.custm_logo}>
              <Link  id="RouterNavLink" to="/">              
              <img
                src="https://content.fieldsmanager.com/System.png"
                alt="logo"
              />
              <span style={{color:"black"}}>Fields</span>
              <span id="whtcolr">Manager</span>
              </Link>        
          </div>
            </nav>
        </div>
      </header>
    </>
  );
};
export default Header;
