import React from 'react';
import styles from './Channel.module.css'
import startIcon from '../../assets/start_icon.png'
import stopIcon from '../../assets/stop_icon.png'
import replayIcon from '../../assets/replay_icon.png'
import errorIcon from '../../assets/errors_icon.png'
import AdvertIcon from '../../assets/advert_icon.png'
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router";




import ReactPlayer from 'react-player'



const Channel = () => {
    const search = useLocation().search;
    const navigate = useNavigate();
  const uid = new URLSearchParams(search).get("user_id");
  const slotId = new URLSearchParams(search).get("slot_id");
    const onGoBack=()=>{
        navigate(`/?user_id=${uid}&slot_id=${slotId}`);
      }
    return(
        <div className={styles.container}>
            <div className="d-flex flex-row justify-content-center" style={{width:'100%'}}>
                <ReactPlayer width="100%"  height='100%'
                    controls={true}
                    playing
                    playIcon={<button>Play</button>}
                    url='https://youtu.be/CvHzWF_iTLo' />
            </div>  
            <div className={styles.icon_container}>
                <button className={styles.back_button} onClick={onGoBack}>Back</button>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <img className={styles.upload_icon} src={AdvertIcon}/>
                  <p className={styles.tittle}>Channel</p>
                </div>
            </div> 
           
                {/* <p className={styles.tittle}>Advert</p> */}
        
           <p className={styles.urllabel_label}>Paste URL to Upload</p>
           <input className={styles.url_input} type="text"  />
           <button className={styles.upload_button}>Upload</button>

           <div className={styles.icon_controll}>
               <div className={styles.icon}>
                   <img className={styles.logo} src={startIcon}/>
                   <p>Start</p>
               </div>
               <div className={styles.icon}>
                   <img className={styles.logo} src={stopIcon}/>
                   <p>Stop</p>
               </div> 
               <div className={styles.icon}>
                   <img className={styles.logo} src={replayIcon}/>
                   <p>Replay</p>
               </div > 
               <div className={styles.icon}>
                   <img className={styles.logo} src={errorIcon}/>
                   <p>Errors</p>
               </div>
            </div> 
             
        </div>
    )
}

export default Channel