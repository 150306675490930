const ReturnIcon = () => {
  return(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="35px"
    viewBox="0 0 24 24"
    width="35px"
    fill="#b6860d"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
  </svg>)
};
export default ReturnIcon;
