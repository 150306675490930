import React, { useState } from 'react';
import styles from './ScoreCard.module.css'
import AdvertIcon from '../../assets/advert_icon.png'
import { useLocation } from "react-router";
import axios from "axios";
import { useNavigate } from 'react-router-dom';




import ReactPlayer from 'react-player'




const Scorecard = () => {
  let mediaUrl= process.env.REACT_APP_URL_SCP;
  const search = useLocation().search;
  const navigate = useNavigate();
  const uid = new URLSearchParams(search).get("user_id");
  const slotId = new URLSearchParams(search).get("slot_id");
  const [matchId, setMatchId] = useState(null);
  const [clubId, setClubId] = useState(null);
  const [leagueName, setLeagueName] = useState(null);
  const onGoBack=()=>{
    navigate(`/?user_id=${uid}&slot_id=${slotId}`);
  }

  const onChangeScoreCardInputs=(e,inputName)=>
  {
    if(inputName==='matchid'){
      setMatchId(e.target.value)

    }
    if(inputName==='clubid'){
      setClubId(e.target.value)
    }
    if(inputName==='leaguename'){
      setLeagueName(e.target.value)
    }

  }

  const onScoreCardSubmit=()=>{
    if(!matchId&&!clubId&&!leagueName){
      axios
      .get(mediaUrl + "/graphic-overlay?user_id=" + uid+"&slot_id="+slotId+"&act=score_card")
      .then((res) => {     
        console.log("response inside onUrlSubmit"+res.data)
       
      });
    }
    else if(matchId&&clubId&&leagueName){
      console.log("matchid "+matchId+" clubid  "+clubId+' leagname '+leagueName)
      axios
      .post(mediaUrl + "/update_StreamUrl",
      {
        params: {
          user_id: uid,
          slot_id: slotId,
          action: 'scorecard',
          matchId:matchId,
          clubId:clubId,
          leagueName:leagueName 
        },
      }
      )
      .then((res) => {  
        axios
        .get(mediaUrl + "/graphic-overlay?user_id=" + uid+"&slot_id="+slotId+"&act=score_card")
        .then((res) => {     
          console.log("response inside onUrlSubmit2"+res.data)
        });   
        console.log("response inside onUrlSubmit"+res.data)
       
      
      });
      
    }
    else{
      console.log("No Api Calls");
    }
    
  }
  

    return(
        <div className={styles.container}>
            <div className="d-flex flex-row justify-content-center" style={{width:'100%'}}>
                <ReactPlayer width="100%"  height='100%'
                    controls={true}
                    playing
                    playIcon={<button>Play</button>}
                    url='https://youtu.be/CvHzWF_iTLo' />
            </div>  
            <div className={styles.icon_container}>
                <button className={styles.back_button}onClick={onGoBack}>Back</button>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <img className={styles.upload_icon} src={AdvertIcon}/>
                  <p className={styles.tittle}>Scorecard</p>
                </div>
            </div> 
           
                {/* <p className={styles.tittle}>Advert</p> */}
        
           <p className={styles.urllabel_label}>Paste URL to Upload</p>
           <input className={styles.url_input} type="text"  />
           <button className={styles.upload_button}>Upload</button>
           <p className={styles.upload_labal}>Upload CricClubs Scorecard </p>
           <p className={styles.matchid_labal}>Enter CricClubs Match Id </p>
           <input className={styles.url_input} type="text"  onChange={(e)=>onChangeScoreCardInputs(e,'matchid')}/>

           <p className={styles.matchid_labal}>Enter CricClubs  Club ID  </p>
           <input className={styles.url_input} type="text" onChange={(e)=>onChangeScoreCardInputs(e,'clubid')} />

           <p className={styles.matchid_labal}>Enter CricClubs League name  onChange={(e)=>onChangeScoreCardInputs(e,'leaguename')}</p>
           <input className={styles.url_input} type="text"  />

           <button className={styles.upload_button} onClick={onScoreCardSubmit}>Upload</button>
             
        </div>
    )
}

export default Scorecard