const StopIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg"  fill="#000000" viewBox="0 0 24 24"  height="18px" width="18px">
        <path  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>)
  };
  export default StopIcon;

 


