import React, { useState } from "react";
import "./videolink.module.css";
import axios from "axios";
// import { environment } from "../../environments/environment";
import { useLocation } from "react-router";
import { useEffect } from "react";
import StreamIcon from "../Icons/StreamIcon";
import AdsIcon from "../Icons/AdsIcon";
import BackupIcon from "../Icons/BackupIcon";
import ReplayIcon from "../Icons/ReplayIcon";
import ReturnIcon from "../Icons/Return";
import StopIcon from "../Icons/StopIcon";
import ScoreIcon from "../Icons/ScoreIcon";
import GraphicsIcon from "../Icons/GraphicsIcon";
import UploadIcon from "../Icons/UploadIcon";


import Popup from "reactjs-popup";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player'
import { BrowserView, MobileView } from 'react-device-detect';
import Spinner from 'react-bootstrap/Spinner';
import e from "cors";
import { useNavigate } from 'react-router-dom';
import { Toast } from 'react-bootstrap';
const VideoLink = () => {
  console.log(process.env.REACT_APP_URL_SCP);
  const search = useLocation().search;
  const uid = new URLSearchParams(search).get("user_id");
  let streamUrl = process.env.REACT_APP_URL_FM;
  let mediaUrl = process.env.REACT_APP_URL_SCP;
  console.log('streamUrl:', streamUrl);
  console.log('mediaUrl:', mediaUrl);
  const slotId = new URLSearchParams(search).get("slot_id");
  const tag = new URLSearchParams(search).get("tag");

  const [play, setPlay] = useState({ status: "On" });
  const [ApiResponse, setApiResponse] = useState({ status: "Idle" });
  const [scoreCard, setScoreCard] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [urldModalShow, setUrldModalShow] = useState(false);
  const [newUrl, setNewUrl] = useState(null);
  const [matchId, setMatchId] = useState(null);
  const [manualScore, setManualScore] = useState({});
  const [manualScoreCardModalShow, setManualScoreCardModalShow] = useState(null);
  const [clubId, setClubId] = useState(null);
  const [leagueName, setLeagueName] = useState(null);
  const [scoreCardModalShow, setscoreCardModalShow] = useState(false);
  const [chaukaModalShow, setChaukaModalShow] = useState(false);
  const [chaukaMatchId, setChaukaMatchId] = useState(null);
  const [urlValid, setUrlValid] = useState(false);
  const [replay, setReplay] = useState(false);
  const [selectedReplayOption, setSelectedReplayOption] = useState('');
  const [replayData, setReplayData] = useState('');
  const [file, setFile] = useState('');
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('success');
  const [toastMessage, setToastMessage] = useState('');
  const [adminPanelModalShow, setAdminPanelModalShow] = useState(false);
  const [text, setText] = useState(false);
  const [textModalShow, setTextModalShow] = useState(false);
  const [ondemandVideos, setOndemandVideos] = useState([]);
  const [advertise, setAdvertise] = useState([]);
  const [showAd, setShowAd]= useState(false);
  const [adPlayLog, setAdPlayLog] = useState({});


  const navigate = useNavigate();









  function graphicModalOpen() {
    setOpen(true);
  }
  function graphicModalClose() {
    setOpen(false);
  }

  function onReplay() {
    setReplay(!replay);
    axios
      .get(mediaUrl + "/replayPreview?user_id=" + uid + "&slot_id=" + slotId)
      .then((res) => {
        console.log(res.data)
        setReplayData(res.data)
      });
  }
  const [auth, setAuth] = useState(false)
  const [authSuccess, setAuthSuccess] = useState(true)

  const [passwordModalShow, setpasswordModalShow] = useState(false)
  const [password, setPassword] = useState('')
  const [clickedButton, setClickedButton] = useState('')







  useEffect(() => {
    playStremOnLoad()
    setIsLoading(true)
    console.log('inside useeffect')
    axios
      .get(streamUrl + "/livestreamAccess?user_id=" + uid + "&slot_id=" + slotId)
      .then((res) => {
        // setPlay(res.data)
        console.log(res.data)
        if (res.data.HTTP_CODE === 200) {
          setIsLoading(false)
          setAuth(true)
        }
        else {
          setIsLoading(false)
          setAuth(false)
        }
      });

  }, []);

  const url = play;
  console.log("api", url);

  // function radioClick() {
  //   console.log("scoreCard", scoreCard);
  //   if (scoreCard === true) {
  //     setScoreCard(false);
  //   } else {
  //     console.log("inside else");
  //     setScoreCard(true);
  //     console.log("in adsPlayHandlerAPI", scoreCard);
  //   }
  //   console.log("before adsPlayHandlerAPI", scoreCard);
  //   adsPlayHandlerAPI(mediaUrl, "scoreCard", scoreCard);
  // }

  async function adsPlayHandlerAPI(link, name, scoreCardVal, card) {
    console.log("init  streamPlayHandlerAPI");
    try {
      setApiResponse({ status: "InProgress" });
      // let response = {a:"b"}
      let response = await axios.get(link, {
        params: {
          user_id: uid,
          slot_id: slotId,
          action: name,
          scoreCard: scoreCardVal,
          act: card,
        },
      });
      console.log("init  ", response.data.Message);
      setApiResponse(response);
      if (name == 'ads')
        showAndCloseToast('success', response.data.Message);
      if (name == 'replay')
        showAndCloseToast('success', 'Replay will be playing soon');
      if (name == 'backup')
        showAndCloseToast('success', 'Backup stream will be playing soon');
      if (name == 'graphic-overlay')
        showAndCloseToast('success', 'Graphics will be playing soon');
    } catch (e) {
      setApiResponse({ status: "Failed" });
    }
    graphicModalClose()
  }
  const onStartClick = () => {
    setClickedButton('start')
    setpasswordModalShow(true)

  }
  const onStopClick = () => {
    setClickedButton('stop')
    setpasswordModalShow(true)



  }
  const handleClosePassword = () => {
    setpasswordModalShow(false)
    setUrldModalShow(false)
    setscoreCardModalShow(false)
    setChaukaModalShow(false)
    setManualScoreCardModalShow(false)
    setMatchId('')
    setClubId('')
    setLeagueName('')

  }

  const onChangePassword = (event) => {
    setPassword(event.target.value)
  }
  const onPasswordSubmit = (event) => {


    axios.post(streamUrl + "/liveStreamPasswordVerify?user_id=" + uid + "&slot_id=" + slotId, {
      auth: {
        password: password
      }
    }
    )
      .then((res) => {
        console.log('response data' + res.data)
        if (res.data.HTTP_CODE === 200) {
          if (clickedButton === 'start') {


            axios.post(mediaUrl + "/invokestreamstart", {
              user_id: uid,
              slot_id: slotId,
              operation: "startChannel"
            })
              .then((res) => {


                if (res.data.HTTP_CODE === 200) {
                  console.log("media live started")
                }
                else {
                  console.log("media live error")
                }
              });



            setAuthSuccess(true)
            console.log('success')

            handleClosePassword()
          }
          if (clickedButton === 'stop') {

            axios
              .get(mediaUrl + "/stopchannel?user_id=" + uid + "&slot_id=" + slotId + "&operation=" + "stopChannel")
              .then((res) => {


                if (res.data.HTTP_CODE === 200) {
                  console.log("media live stoped")

                  setAuthSuccess(false)
                }
                else {
                  console.log("media live stop error")
                }
              });
            handleClosePassword()

          }
        }
        else {

          setAuthSuccess(false)
          console.log('fail')
        }
        setPassword('')
      });

  }

  const onChangeUrl = (event) => {
    setNewUrl(event.target.value)
    matchYoutubeUrl(event.target.value)
  }
  const onStreamClick = () => {
    console.log('inside onStreamClick')
    setUrldModalShow(true)

  }

  const playStremOnLoad = () => {
    axios
      .post(mediaUrl + "/getStreamUrl?user_id=" + uid + "&slot_id=" + slotId)
      .then((res) => {
        console.log("response inside onUrlSkip" + res.data.body)
        setPlay(res.data.body)
        handleClosePassword()

      });
  }

  const onUrlSkip = () => {
    axios
      .post(mediaUrl + "/getStreamUrl?user_id=" + uid + "&slot_id=" + slotId)
      .then((res) => {
        console.log("response inside onUrlSkip" + res.data.body)
        setPlay(res.data.body)
        handleClosePassword()

      });

    axios
      .post(mediaUrl + "/input_switch?user_id=" + uid + "&slot_id=" + slotId)
      .then((res) => {
        console.log("response inside onUrlSkip" + res.data.body)
        handleClosePassword()
      });

  }

  const replayClick = () => {
    let today = new Date();
    let timestamp = today.toISOString().split("T")[1].split(".")[0];
    adsPlayHandlerAPI(mediaUrl + "/replay?user_id=" + uid + "&slot_id=" + slotId + "&timestamp=" + timestamp, "replay", scoreCard)
    // axios
    //  .get(mediaUrl + "/replay?user_id=" + uid+"&slot_id="+slotId+"&timestamp="+timestamp)
    //  .then((res) => {     
    //    console.log("response inside replayClick"+res.data.body)
    //    handleClosePassword()
    //  });
  }
  const rekognitionReplayClick = () => {
    axios.get(mediaUrl + "/rekognition-replay?user_id=" + uid + "&slot_id=" + slotId).then((res) => {
      console.log("response inside rekognitionReplayClick" + res.data.body);
    });
  };

  const onUrlSubmit = () => {
    console.log('inside onUrlSubmit')
    console.log('new url' + newUrl)
    axios
      .post(mediaUrl + "/update_StreamUrl",
        {

          params: {
            user_id: uid,
            slot_id: slotId,
            action: 'stream',
            stream_url: newUrl

          },

        }
      )
      .then((res) => {
        console.log("response inside onUrlSubmit" + res.data)
        axios
          .post(mediaUrl + "/input_switch?user_id=" + uid + "&slot_id=" + slotId)
          .then((res) => {
            console.log("response inside onUrlSubmit2" + res.data)
          });
        setPlay(newUrl)
        handleClosePassword()

      });
  }

  const onScoreCardClick = () => {
    console.log('inside OnScoreCardClick')
    setscoreCardModalShow(true)
  }

  const onManualScoreCardClick = () => {
    setManualScoreCardModalShow(true)
  }

  const onChangeScoreCardInputs = (e, inputName) => {
    if (inputName === 'matchid') {
      setMatchId(e.target.value)

    }
    if (inputName === 'clubid') {
      setClubId(e.target.value)
    }
    if (inputName === 'leaguename') {
      setLeagueName(e.target.value)
    }

  }

  const onChangeManualScoreCardInputs = (e, inputName) => {
    setManualScore(prevState => ({
      ...prevState,
      [inputName]: e.target.value
    }));
  
    console.info("dsniud", manualScore);
  };

  const onScoreCardSubmit = () => {
    if (!matchId && !clubId && !leagueName) {
      axios
        .get(mediaUrl + "/graphic-overlay?user_id=" + uid + "&slot_id=" + slotId + "&act=score_card")
        .then((res) => {
          console.log("response inside onUrlSubmit" + res.data)
          setscoreCardModalShow(false)
          handleClosePassword()
        });
    }
    else if (matchId && clubId && leagueName) {
      console.log("matchid " + matchId + " clubid  " + clubId + ' leagname ' + leagueName)
      axios
        .post(mediaUrl + "/update_StreamUrl",
          {
            params: {
              user_id: uid,
              slot_id: slotId,
              action: 'scorecard',
              matchId: matchId,
              clubId: clubId,
              leagueName: leagueName
            },
          }
        )
        .then((res) => {
          axios
            .get(mediaUrl + "/graphic-overlay?user_id=" + uid + "&slot_id=" + slotId + "&act=score_card")
            .then((res) => {
              showAndCloseToast('success', 'Score card will update soon');
              console.log("response inside onUrlSubmit2" + res.data)
            });
          console.log("response inside onUrlSubmit" + res.data)
          setscoreCardModalShow(false)
          handleClosePassword()

        });

    }
    else {
      console.log("No Api Calls");
    }
    setMatchId('')
    setClubId('')
    setLeagueName('')

  }

  const onManualScoreCardSubmit = () =>{
    console.info("req",manualScore)
    axios
        .post(mediaUrl + "/update_StreamUrl",
          {
            params: {
              user_id: uid,
              slot_id: slotId,
              action: 'manualscorecard',
              manualScore: manualScore
            },
          }
        )
        .then((res) => {
          setManualScore({})
          axios
            .get(mediaUrl + "/graphic-overlay?user_id=" + uid + "&slot_id=" + slotId + "&act=manualscorecard")
            .then((res) => {
              showAndCloseToast('success', 'Score card will update soon');
              console.log("response inside onUrlSubmit2" + res.data)
            });
          console.log("response inside onUrlSubmit" + res.data)
          setManualScoreCardModalShow(false)
          handleClosePassword()
        })
      
  }


  const onChaukaClick = () => {
    console.log('inside OnChaukaClick')
    setChaukaModalShow(true)
  }

  const onAddClick = () => {
    setAddModalShow(true)
  }
  
  const onChangeChaukaInputs = (e, inputName) => {
    if (inputName === 'matchid') {
      setChaukaMatchId(e.target.value)

    }

  }

  const onChaukaSubmit = () => {
    console.log("chaukamatchid " + chaukaMatchId)

    axios
      .post(mediaUrl + "/update_StreamUrl",
        {
          params: {
            user_id: uid,
            slot_id: slotId,
            action: 'chauka',
            matchId: chaukaMatchId,

          },
        }
      )
      .then((res) => {
        console.log("response inside onUrlSubmit" + res.data)
        setscoreCardModalShow(false)
        handleClosePassword()
        setChaukaModalShow(false)

      });



  }


  function matchYoutubeUrl(url) {


    if (url != undefined || url != '') {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        // Do anything for being valid
        // if need to change the url to embed url then use below line
        setUrlValid(true)
      }
      else {
        setUrlValid(false)
      }
    }

  }
  const onReplaySelect = (e) => {
    setSelectedReplayOption(e)
    console.log(e)
  }
  const onReplaySubmit = () => {
    console.log('submit ' + selectedReplayOption)
    axios
      .post(mediaUrl + "/replay?user_id=" + uid + "&slot_id=" + slotId + "&s3url=" + selectedReplayOption)
      .then((res) => {


        if (res.data.statusCode === 200) {
          console.log("Replay Success")
        }
        else {
          console.log("Replay failed")
        }
      });
    onReplay()
  }
  const onTimeClick = (url) => {
    setPlay(url)
  }
  const onFileSelect = (event) => {
    console.log(event.target.files[0])
    setFile(event.target.files[0])
  }

  const onSubmitVideo = () => {
    console.log(file)
    //get presigned url
    axios
      .get(mediaUrl + "/mp4/url?user_id=" + uid + "&slot_id=" + slotId)
      .then((res) => {
        console.log(res)
        // upload file to s3
        axios
          .put(res.data.signed_url, file, {
            headers: {
              'Content-Type': 'video/mp4',
            }
          })
          .then((res) => {

          })

      });

    uploadModalClose()
  }

  const onUploadClick = () => {
    setUploadModalShow(true)
  }
  const uploadModalClose = () => {
    setUploadModalShow(false)
  }
  const addModalClose = () => {
    setAddModalShow(false)
  }

  //FUNCTION TO GET ADS
  const onAdButtonClick = () => {
    setShowAd(true);
    axios.get(mediaUrl+"/getAd",{
      params:{
        user_id: uid,
        slot_id: slotId
      }
    }).then((res)=>{
      setAdvertise(res.data.signedObjUrl)
      setAdPlayLog(res.data.AdsLog)
    })
  }

  
  //FUNCTION TO PLAY AD IN LIVE
  const onAdPlayButtonClick = (key) =>{
    axios.get(mediaUrl+"/playAd", {
      params:{
        user_id: uid,
        slot_id: slotId,
        Key:key
      }
    }).then((res)=>{
      if (res.data.Resp.statusCode === 200) {
        showAndCloseToast('Success', 'Ads will be played soon.')
      }else{
        showAndCloseToast('info', 'Failed to play ad in live')
      }
      setAdPlayLog(res.data.AdsLog)
      console.log(res.data)
    })

  }

  //FUNCTION TO GET ONFIELD VIDEOS
  const onOnFieldClick = () => {
    setShowAd(false);

    axios.get(mediaUrl+"/getOnfield",{
      params:{
        user_id: uid,
        slot_id: slotId
      }
    }).then((res)=>{
      console.log("Ondemand Videos", res.data)
      setOndemandVideos(res.data)
    })
  }

  //FUNCTION TO PLAY ONFIELD VIDEO IN LIVE
  const onFieldButtonClick = (key) =>{
    axios.get(mediaUrl+"/playOnfield", {
      params:{
        user_id: uid,
        slot_id: slotId,
        Key:key
      }
    }).then((res)=>{
      console.log("On demand response",res)
      
      onOnFieldClick()
    })

  }

  const onClickChannelManagement = () => {
    const storedCombo = sessionStorage.getItem('uidSlotIdCombo');
    if (storedCombo === `${uid}-${slotId}`) {
      navigate(`/manage?user_id=${uid}&slot_id=${slotId}`);
    } else {
      setpasswordModalShow(true);
    }
  };

  const onGoChannelMangage = () => {
    setIsLoading(true)
    axios
      .post(streamUrl + "/liveStreamPasswordVerify?user_id=" + uid + "&slot_id=" + slotId, {
        auth: {
          password: password,
        },
      })
      .then((res) => {
        console.log("response data: " + res.data);
        setIsLoading(false)
        if (res.data.HTTP_CODE === 200) {
          sessionStorage.setItem('uidSlotIdCombo', `${uid}-${slotId}`);
          setTimeout(() => {
            sessionStorage.setItem('uidSlotIdCombo', `${uid}-${slotId}`);
          }, 60 * 60 * 1000); // 5 minutes in milliseconds
          navigate(`/manage?user_id=${uid}&slot_id=${slotId}`);
        }

        setPassword("");
      });
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const showAndCloseToast = (type, message) => {
    setToastType(type);
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 6000); // Adjust the delay (in milliseconds) to your desired duration
  };

  const onClickAdminPanel = () => {
    setAdminPanelModalShow(true)
  }
  const handleCloseadminPanelModal = () => {
    setMatchId('')
    setClubId('')
    setAdminPanelModalShow(false)
  }


  const onAdminPanelSubmit = () => {
    setMatchId('')
    setClubId('')
    if (matchId && clubId) {
      console.log("matchid " + matchId + " clubid  " + clubId)
      axios
        .post(mediaUrl + "/update_StreamUrl",
          {
            params: {
              user_id: uid,
              slot_id: slotId,
              action: 'adminpanel',
              matchId: matchId,
              clubId: clubId,
            },
          }
        )
        .then((res) => {
          axios
            .get(mediaUrl + "/graphic-overlay?user_id=" + uid + "&slot_id=" + slotId + "&act=admin_panel")
            .then((res) => {
              showAndCloseToast('success', 'Admin panel Graphics will be played soon');
              console.log("response inside onUrlSubmit2" + res.data)
            });
          console.log("response inside onUrlSubmit" + res.data)
          setAdminPanelModalShow(false)
          handleClosePassword()

        });

    }

  }



  const onClickTextButton = () => {
    setTextModalShow(true)
  }
  const handleClosetextModal = () => {
    setTextModalShow(false)
  }
  const onTextSubmit = () => {

    if (text) {
      console.log("matchid " + matchId + " clubid  " + clubId)
      axios
        .post(mediaUrl + "/update_StreamUrl",
          {
            params: {
              user_id: uid,
              slot_id: slotId,
              action: 'textcard',
              text: text,
            },
          }
        )
        .then((res) => {
          axios
            .get(mediaUrl + "/graphic-overlay?user_id=" + uid + "&slot_id=" + slotId + "&act=text_card")
            .then((res) => {
              showAndCloseToast('success', 'Text Graphics will be played soon');
              console.log("response inside onUrlSubmit2" + res.data)
            });
          console.log("response inside onUrlSubmit" + res.data)
          setTextModalShow(false)
          handleClosePassword()

        });

    }

  }

  const onChangeText = (e) => {
    setText(e.target.value)
  }
  let replayContent = 'loading..'
  if (replayData) {
    replayContent = replayData.map(item => {
      return (<>
        <Form.Check aria-label="option 1" type="radio" style={{ float: 'left', margin: '10px' }} name='test' onChange={() => onReplaySelect(item.s3url)} value={item.time} key={item.time} />
        <button
          onClick={() => onTimeClick(item.s3url)}
        >
          <span>{item.time}</span>
        </button>

      </>)
    })
  }
  if (isLoading)
    return (
      <>
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </>
    )
  if (auth)
    return (
      <>
        <div className="container">
          <div className="d-flex bd-highlight">
            <div className="container">
              <div className="d-flex flex-row justify-content-center">
                <div className="col-md-3" style={{ maxHeight: '230px' }}>
                  {/* Video player code here */}
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    controls={true}
                    playing
                    playIcon={<button>Play</button>}
                    url={url}
                  />
                </div>
              </div>

              <div style={{ marginTop: '40px', marginBottom: '30px', position: 'fixed', top: '0', left: '50%', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Toast show={showToast} onClose={handleCloseToast}>
                  <Toast.Header style={{ background: toastType === 'success' ? '#47b354' : toastType === 'fail' ? 'red' : '#fac446' }}>
                    <strong style={{ color: 'white' }} className="me-auto">
                      {toastType === 'success' ? 'Success' : toastType === 'fail' ? 'Fail' : 'Info'}
                    </strong>
                  </Toast.Header>
                  <Toast.Body style={{ color: toastType === 'success' ? '#47b354' : toastType === 'fail' ? 'red' : '#a87b11' }}>
                    {toastMessage}
                  </Toast.Body>
                </Toast>
              </div>
              <br />
              {!scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !replay && !isOpen && !uploadModalShow && !adminPanelModalShow && !textModalShow && <div >
                {<div className="d-flex justify-content-center">
                  <span style={{ color: "#b6860d" }}>
                    LIVE STREAMING CONTROL PANEL
                  </span>
                </div>
                }





                <br />
                {/* button section starts */}
                <div className="d-flex align-items-center justify-content-center">

                </div>
                <div className="d-flex flex-row justify-content-center mx-1">
                  <div className="col-md-4 col-lg-4">




                    {authSuccess && !scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div className="d-flex justify-content-center ">

                      <button
                        name="stream"
                        // onClick={() => setPlay(!play)}
                        // onClick={() =>
                        //   adsPlayHandlerAPI(
                        //     streamUrl + `/streams`,
                        //     "stream",
                        //     scoreCard
                        //   )
                        // }
                        onClick={onStreamClick}
                        type="button"
                      >
                        <StreamIcon />
                        <span>Stream</span>
                      </button>
                      <button
                        name="ads"
                        // onClick={() =>
                        //   adsPlayHandlerAPI(mediaUrl + `/ads`, "ads", scoreCard)
                        // }
                        onClick={onAddClick}
                        type="button"
                      >
                        <AdsIcon />
                        <span>Ads</span>
                      </button>








                    </div>}


                    {authSuccess && !scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div className="d-flex justify-content-center ">

                      <button name="graphics" type="button"
                        // disabled='true' 
                        // style={{ opacity: ' 0.5',
                        //  pointerEvents: 'none' }} 
                        onClick={onReplay}>
                        <ReplayIcon />
                        <span>

                          Replay Preview
                        </span>
                      </button>

                      <button
                        name="replay"
                        type="button"
                        // disabled='true'
                        // style={{ opacity:' 0.5',
                        // pointerEvents: 'none'}}
                        onClick={() =>
                          replayClick()
                        }
                      >
                        <ReplayIcon />
                        <span>Replay</span>
                      </button>
                    </div>}

                    {authSuccess && !scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div className="d-flex justify-content-center ">

                      <button name="graphics" type="button" onClick={onClickAdminPanel}>

                        <span>

                          Admin Panel
                        </span>
                      </button>

                      <button
                        name="replay"
                        type="button"
                        onClick={() =>
                          onClickTextButton()
                        }
                      >

                        <span>Text</span>
                      </button>
                    </div>}

                    {authSuccess && !scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div className="d-flex justify-content-center ">

                      <button name="graphics" type="button" onClick={graphicModalOpen}>
                        <GraphicsIcon />
                        <span>

                          Graphics
                        </span>
                      </button>
                    </div>}


                    {authSuccess && !scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div className="d-flex align-items-center justify-content-center">

                      <button
                        name="score-card"
                        // onClick={() =>
                        //   adsPlayHandlerAPI(
                        //     streamUrl + `/graphic-overlay`,
                        //     "graphic-overlay",
                        //     scoreCard,
                        //     "score_card"
                        //   )
                        // }
                        onClick={onScoreCardClick}
                        type="button"
                      >
                        <ScoreIcon />
                        <span>Score Card</span>
                      </button>

                      <button
                        name="manual-score-card"
                        // onClick={() =>
                        //   adsPlayHandlerAPI(
                        //     streamUrl + `/graphic-overlay`,
                        //     "graphic-overlay",
                        //     scoreCard,
                        //     "score_card"
                        //   )
                        // }
                        onClick={onManualScoreCardClick}
                        type="button"
                      >
                        <ScoreIcon />
                        <span>Manual score Card</span>
                      </button>

                      {/* <button
                        name="score-card-chauka"
                        // onClick={() =>
                        //   adsPlayHandlerAPI(
                        //     streamUrl + `/graphic-overlay`,
                        //     "graphic-overlay",
                        //     scoreCard,
                        //     "score_card_chauka"
                        //   )
                        // }
                        onClick={onChaukaClick}
                        type="button"
                        disabled='true'
                        style={{
                          opacity: ' 0.5',
                          pointerEvents: 'none'
                        }}
                      >
                        <ScoreIcon />
                        <span>Score - Chauka</span>
                      </button> */}
                    </div> 
                    }

                    {authSuccess && !scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div className="d-flex align-items-center justify-content-center">


                      {tag ? (
                        <a href={process.env.REACT_APP_URL_TAG}>
                          <button>
                            <BackupIcon />
                            <span>Tag Frames</span>
                          </button>
                        </a>
                      ) : (
                        ""
                      )}

                      <button
                        name="rekognition-replay"
                        type="button"
                        // disabled='true'
                        // style={{
                        //   opacity: ' 0.5',
                        //   pointerEvents: 'none'
                        // }}
                        onClick={() => rekognitionReplayClick()}
                      >
                        <ReplayIcon />
                        <span>Rekognition Replay</span>
                      </button>

                      <button
                        name="backup"
                        type="button"
                        onClick={() =>
                          adsPlayHandlerAPI(
                            mediaUrl + `/backup`,
                            "backup",
                            scoreCard
                          )
                        }
                      >
                        <BackupIcon />
                        <span>Backup</span>
                      </button>
                    </div>}

                    <div className="d-flex align-items-center justify-content-center">

                      {authSuccess && !scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div className="d-flex align-items-center justify-content-center">



                        {/*                 
                <Popup
                  trigger={
                    <button name="graphics" type="button">
                      <span>
                        <StreamIcon />
                        Graphics
                      </span>
                    </button>
                  }
                  position="right center"
                >
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "four" )
                  }>
                    <span>It's a 4</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "six")
                  }>
                    <span>It's a 6</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "single")
                  }>
                    <span>It's a single 1</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "catch-out")
                  }>
                    <span>Caught Out</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "bowled")
                  }>
                    <span>Clean Bowled</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "decision-pending")
                  }>
                    <span>Decision Pending</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "free-hit")
                  }>
                    <span>Free Hit</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "run-out")
                  }>
                    <span>Run Out</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "scene-on")
                  }>
                    <span>Scene On</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "out")
                  }>
                    <span>That's Out</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "watch")
                  }>
                    <span>Watch</span>
                  </button>
                  <button onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/graphic-overlay`, "graphic-overlay", scoreCard, "T20")
                  }>
                    <span>T20</span>
                  </button>
                </Popup> */}
                      </div>}





                    </div>

                    {!scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div className="d-flex justify-content-center ">
                      <button
                        name="rekognition-replay"
                        type="button"
                        onClick={() => onClickChannelManagement()}
                      >

                        <span>Channel Management </span>
                      </button>
                    </div>}
                  </div>

                </div>

                {authSuccess && !scoreCardModalShow && !urldModalShow && !chaukaModalShow && !addModalShow && !adminPanelModalShow && !textModalShow && <div> <div className="d-flex justify-content-center">
                  {/* Status: {ApiResponse.status} */}
                </div>

                </div>
                }

              </div>}



            </div>

            <Modal show={passwordModalShow} onHide={handleClosePassword}>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicPassword" >
                    <Form.Label>Enter the Password send to your mail</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={onChangePassword} />
                  </Form.Group>
                  <Button variant="warning" onClick={onGoChannelMangage}>
                    Submit
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>

            {/* url change modal */}

            <ReactModal
              id="graphic-modal"
              isOpen={urldModalShow}
              onRequestClose={handleClosePassword}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "50%", // Use "50%" to horizontally center the modal
                  transform: "translateX(-50%)", // Translate the modal by -50% of its width to center it properly
                  bottom: "0px",
                  borderRadius: "0px",
                  width: "100%", // Adjust the width for mobile view
                  maxWidth: "400px", // Adjust the maximum width for desktop view
                },
              }}
            >
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Enter your URL</Form.Label>
                    <Form.Control placeholder="new URL" onChange={onChangeUrl} />
                  </Form.Group>
                  <Button style={{ marginLeft: '0px' }} variant="warning" onClick={onUrlSubmit} disabled={!urlValid}>
                    Submit
                  </Button>
                  <Button variant="warning" onClick={onUrlSkip}>
                    Skip
                  </Button>
                </Form>
              </Modal.Body>
            </ReactModal>

            {/* Score card modal */}

            <ReactModal
              id="graphic-modal"
              isOpen={scoreCardModalShow}
              onRequestClose={handleClosePassword}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0px",
                  borderRadius: "0px",
                  width: "100%",
                  maxWidth: "400px",
                },
              }}
            >
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Enter Match ID</Form.Label>
                    <Form.Control placeholder="Match ID" onChange={(e) => onChangeScoreCardInputs(e, 'matchid')} />
                    <Form.Label>Enter Club ID</Form.Label>
                    <Form.Control placeholder="Club ID" onChange={(e) => onChangeScoreCardInputs(e, 'clubid')} />
                    <Form.Label>Enter League Name</Form.Label>
                    <Form.Control placeholder="League Name" onChange={(e) => onChangeScoreCardInputs(e, 'leaguename')} />
                  </Form.Group>

                  <Button style={{ marginLeft: '0px' }} variant="warning" onClick={onScoreCardSubmit}>
                    Submit
                  </Button>
                  <Button variant="warning" onClick={handleClosePassword}>
                    Cancel
                  </Button>

                </Form>
              </Modal.Body>
            </ReactModal>


            {/* Score card modal */}

            <ReactModal
              id="graphic-modal"
              isOpen={manualScoreCardModalShow}
              onRequestClose={handleClosePassword}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0px",
                  borderRadius: "0px",
                  width: "100%",
                  maxWidth: "400px",
                },
              }}
            >
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Match 1</Form.Label>
                    <div id="player-row" style={{ display: 'flex' }}>
                    <Form.Control placeholder="Player" onChange={(e) => onChangeManualScoreCardInputs(e, 'player1a')} />
                    <Form.Control type="number" placeholder="Score" onChange={(e) => onChangeManualScoreCardInputs(e, 'score1a')} />
                    </div>
                    <div className="player-row" style={{ display: 'flex' }}>
                    <Form.Control placeholder="Player" onChange={(e) => onChangeManualScoreCardInputs(e, 'player2a')} />
                    <Form.Control type="number" placeholder="Score" onChange={(e) => onChangeManualScoreCardInputs(e, 'score2a')} />
                    </div>

                    <Form.Label>Match 2</Form.Label>
                    <div className="player-row" style={{ display: 'flex' }}>
                      <Form.Control placeholder="Player" onChange={(e) => onChangeManualScoreCardInputs(e, 'player1b')} />
                      <Form.Control type="number" placeholder="Score" onChange={(e) => onChangeManualScoreCardInputs(e, 'score1b')} />
                    </div>
                    <div className="player-row" style={{ display: 'flex' }}>
                    <Form.Control placeholder="Player" onChange={(e) => onChangeManualScoreCardInputs(e, 'player2b')} />
                    <Form.Control type="number" placeholder="Score" onChange={(e) => onChangeManualScoreCardInputs(e, 'score2b')} />
                    </div>
                    <Form.Label>Match 3</Form.Label>
                    <div className="player-row" style={{ display: 'flex' }}>
                    <Form.Control placeholder="Player" onChange={(e) => onChangeManualScoreCardInputs(e, 'player1c')} />
                    <Form.Control type="number" placeholder="Score" onChange={(e) => onChangeManualScoreCardInputs(e, 'score1c')} />
                    </div>
                    <div className="player-row" style={{ display: 'flex' }}>
                    <Form.Control placeholder="Player" onChange={(e) => onChangeManualScoreCardInputs(e, 'player2c')} />
                    <Form.Control type="number" placeholder="Score" onChange={(e) => onChangeManualScoreCardInputs(e, 'score2c')} />
                    </div>
                    <Form.Label>Match 4</Form.Label>
                    <div className="player-row" style={{ display: 'flex' }}>
                    <Form.Control placeholder="Player" onChange={(e) => onChangeManualScoreCardInputs(e, 'player1d')} />
                    <Form.Control type="number" placeholder="Score" onChange={(e) => onChangeManualScoreCardInputs(e, 'score1d')} />
                    </div>
                    <div className="player-row" style={{ display: 'flex' }}>
                    <Form.Control placeholder="Player" onChange={(e) => onChangeManualScoreCardInputs(e, 'player2d')} />
                    <Form.Control type="number" placeholder="Score" onChange={(e) => onChangeManualScoreCardInputs(e, 'score2d')} />
                    </div>
                  </Form.Group>

                  <Button style={{ marginLeft: '0px' }} variant="warning" onClick={onManualScoreCardSubmit}>
                    Submit
                  </Button>
                  <Button variant="warning" onClick={handleClosePassword}>
                    Cancel
                  </Button>

                </Form>
              </Modal.Body>
            </ReactModal>

            {/* Score Chauka Modal*/}

            <ReactModal
              id="graphic-modal"
              isOpen={chaukaModalShow}
              ariaHideApp={false}
              onRequestClose={handleClosePassword}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0px",
                  borderRadius: "0px",
                  width: "100%",
                  maxWidth: "400px",
                },
              }}
            >
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Enter Match ID</Form.Label>
                    <Form.Control placeholder="Match ID" onChange={(e) => onChangeChaukaInputs(e, 'matchid')} />
                  </Form.Group>

                  <Button style={{ marginLeft: '0px' }} variant="warning" onClick={onChaukaSubmit} disabled={!chaukaMatchId}>
                    Submit
                  </Button>
                  <Button variant="warning" onClick={handleClosePassword}>
                    Cancel
                  </Button>

                </Form>
              </Modal.Body>
            </ReactModal>







            <ReactModal
              id="graphic-modal"
              isOpen={isOpen}
              onRequestClose={graphicModalClose}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",

                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  borderRadius: "0px",
                },
              }}
            >
              <table>
                <tr>
                  <td>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "four"
                        )
                      }
                    >
                      <span>It's a 4</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "six"
                        )
                      }
                    >
                      <span>It's a 6</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "single"
                        )
                      }
                    >
                      <span>It's a single 1</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "catch-out"
                        )
                      }
                    >
                      <span>Caught Out</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "bowled"
                        )
                      }
                    >
                      <span>Clean Bowled</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "decision-pending"
                        )
                      }
                    >
                      <span>Decision Pending</span>
                    </button>
                  </td>
                  <td colSpan={2}>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "free-hit"
                        )
                      }
                    >
                      <span>Free Hit</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "run-out"
                        )
                      }
                    >
                      <span>Run Out</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "scene-on"
                        )
                      }
                    >
                      <span>Scene On</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "out"
                        )
                      }
                    >
                      <span>That's Out</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "watch"
                        )
                      }
                    >
                      <span>Watch</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          mediaUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "T20"
                        )
                      }
                    >
                      <span>T20</span>
                    </button>
                  </td>
                </tr>
                <tr>

                  <td width="90px"></td>
                  <td height="60px">
                    <p onClick={graphicModalClose}>
                      <ReturnIcon />
                    </p>
                  </td>
                </tr>
              </table>
              <table>
                <tr>
                  <td className="d-flex justify-content-center" >Status: {ApiResponse.status}</td>
                </tr>
              </table>
            </ReactModal>



            <ReactModal
              id="graphic-modal"
              isOpen={replay}
              onRequestClose={onReplay}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",

                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "35%",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  borderRadius: "0px",
                },
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td >
                      {replayContent}
                    </td>
                  </tr>
                  {replayData && <tr>
                    <td><button
                      onClick={onReplaySubmit}
                    >
                      <span>Submit</span>
                    </button></td>

                    <td height="60px">
                      <p onClick={onReplay}>
                        <ReturnIcon />
                      </p>
                    </td>
                  </tr>}
                </tbody>
              </table>


            </ReactModal>

            <ReactModal
              id="upload"
              isOpen={uploadModalShow}
              onRequestClose={uploadModalClose}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "35%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0px",
                  borderRadius: "0px",
                  width: "100%",
                  maxWidth: "400px",
                },
              }}
            >
              <div>
                <input
                  style={{ margin: '10px' }}
                  type="file"
                  onChange={onFileSelect}
                />

                {/* <button style={{margin:'0px'}} type="button" onClick={onSubmitVideo}>
        <span>Submit</span>
      </button>
      <button type="button" onClick={uploadModalClose}>
        <span>Cancel</span>
      </button> */}

                <Button style={{ marginLeft: '0px' }} variant="warning" onClick={onSubmitVideo}>
                  Submit
                </Button>
                <Button variant="warning" onClick={uploadModalClose}>
                  Cancel
                </Button>

              </div>
            </ReactModal>

            <ReactModal
              id="upload"
              isOpen={addModalShow}
              onRequestClose={addModalClose}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "35%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0px",
                  borderRadius: "0px",
                  width: "100%",
                  maxWidth: "400px",
                },
                alignItems: "center",
              }}
            >
              <div>
                <input
                  style={{ margin: '10px', visibility: 'hidden' }}
                  type="file"
                />
                <div className="d-flex justify-content-between">
                  <button
                    style={{ float: 'left' }}
                    type="button"
                    onClick={onAdButtonClick}
                    // onClick={() =>
                    //   adsPlayHandlerAPI(mediaUrl + '/ads', 'ads', scoreCard)
                    // }
                  >
                    <span>Ad</span>
                  </button>
                  <button type="button" onClick={onOnFieldClick}>
                    <span>On Field</span>
                  </button>
                  <p onClick={addModalClose}>
                    <ReturnIcon />
                  </p>
                </div>
                <div>
                  {showAd?
                  <div>
                  {advertise&&(advertise.map(item=>{
                      return(<>
                        <ReactPlayer
                    width="100%"
                    height="100%"
                    controls={true}
                    playing = {false}
                    playIcon={<button>Play</button>}
                    url={item.url}
                    />
                    <div className="d-flex justify-content-between"> 
                    <button onClick={()=>{onAdPlayButtonClick(item.Key)}}>Play In Live</button>
                    <span>{adPlayLog[item.Key]?`${adPlayLog[item.Key]} times played`:"Not yet played"}</span>
                    </div>
                    </>
                      )
                    }))}
                  </div>
                  :
                  <div>
                  {ondemandVideos&&(ondemandVideos.map(item=>{
                      return(<>
                        <ReactPlayer
                    width="100%"
                    height="100%"
                    controls={true}
                    playing = {false}
                    playIcon={<button>Play</button>}
                    url={item.url}
                    />
                    <button onClick={()=>{onFieldButtonClick(item.Key)}}>Play In Live</button>
                    </>
                      )
                    }))}
                    </div>
}
                  </div>
              </div>
            </ReactModal>

            <ReactModal
              id="graphic-modal"
              isOpen={adminPanelModalShow}
              onRequestClose={handleCloseadminPanelModal}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0px",
                  borderRadius: "0px",
                  width: "100%",
                  maxWidth: "400px",
                },
              }}
            >
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Enter Match ID</Form.Label>
                    <Form.Control placeholder="Match ID" onChange={(e) => onChangeScoreCardInputs(e, 'matchid')} />
                    <Form.Label>Enter Club ID</Form.Label>
                    <Form.Control placeholder="Club ID" onChange={(e) => onChangeScoreCardInputs(e, 'clubid')} />

                  </Form.Group>

                  <Button style={{ marginLeft: '0px' }} variant="warning" onClick={onAdminPanelSubmit}>
                    Submit
                  </Button>
                  <Button variant="warning" onClick={handleCloseadminPanelModal}>
                    Cancel
                  </Button>

                </Form>
              </Modal.Body>
            </ReactModal>

            <ReactModal
              id="graphic-modal"
              isOpen={textModalShow}
              onRequestClose={handleClosetextModal}
              ariaHideApp={false}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0px",
                  borderRadius: "0px",
                  width: "100%",
                  maxWidth: "400px",
                },
              }}
            >
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Enter The Text</Form.Label>
                    <Form.Control placeholder="Text" onChange={(e) => onChangeText(e)} />


                  </Form.Group>

                  <Button style={{ marginLeft: '0px' }} variant="warning" onClick={onTextSubmit}>
                    Submit
                  </Button>
                  <Button variant="warning" onClick={handleClosetextModal}>
                    Cancel
                  </Button>

                </Form>
              </Modal.Body>
            </ReactModal>


                    


          </div>
        </div>
      </>
    );
  else
    return (
      <>
        <div className="d-flex justify-content-center">
          <h1>Auth Failed</h1>
        </div>
      </>
    )
};
export default VideoLink;
