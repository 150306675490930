import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router";
import styles from './Images.module.css'
import ContrastIcon from '../../assets/contrast_icon.png'
import WidthIcon from '../../assets/width_icon.png'
import OpacityIcon from '../../assets/opacity_icon.png'
import positionIcon from '../../assets/position_icon.png'
import ContrastIconPassive from '../../assets/contrast_icon_passive.png'
import WidthIconPassive from '../../assets/width_icon_passive.png'
import OpacityIconPassive from '../../assets/opacity_icon_passive.png'
import positionIconPassive from '../../assets/position_icon_passive.png'
// import HeightIcon from '../../assets/height_icon.png'
import WidthIconSmall from '../../assets/width_icon_small.png'
import HeightIconSmall from '../../assets/height_icon_small.png'
import PositionIconSmall from '../../assets/position_icon_small.png'
import ContrastIconSmall from '../../assets/contrast_icon_small.png'
import OpacityIconSmall from '../../assets/opacity_icon_small.png'
import axios from "axios";
import {Button} from '@material-ui/core';

import AdvertIcon from '../../assets/advert_icon.png'
import { Slider } from '@material-ui/core';
import uploadIcon from '../../assets/upload_icon.png'
import { useNavigate } from 'react-router-dom';




import ReactPlayer from 'react-player'



const Images = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const uid = new URLSearchParams(search).get("user_id");
  const slotId = new URLSearchParams(search).get("slot_id");
  let mediaUrl= process.env.REACT_APP_URL_SCP;
    const [previewImage, setPreviewImage] = useState({
        src: '', alt: '', width: 0, height: 0, x: 0,
        y: 0, opacity: 1, contrast: 100,file: null 
    });
const [previewImages, setPreviewImages] = useState([]); 
const [previewMode, setPreviewMode] = useState(false); 
const [selectedIndex, setSelectedIndex] = useState(0); 


   
    



    const [controls, setControls] = useState({
        dimension: false,
        position: false,
        contrast: false
    });

    const boxRef = useRef(null);
    useEffect(() => {
        console.log('inside useeff')
        if (boxRef.current) {
            const boxRect = boxRef.current.getBoundingClientRect();
            setBoxDimensions({
              width: boxRect.width,
              height: boxRect.height,
            });
            console.log(boxRect.width)
          }
          
    }, [boxRef.current, previewImage]);

   

    const [boxDimensions, setBoxDimensions] = useState({ width:0, height: 0 });

    const handleFileSelect = () => {
        const fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/*');
        fileInput.addEventListener('change', handleFileUpload);
        fileInput.click();
    };

    const handleFileUpload = (event) => {
        setPreviewMode(true)
        const file = event.target.files[0];
        const reader = new FileReader();
        let index
        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = new Image();
            img.onload = () => {
                const newImage = {
                    src: reader.result,
                    width: img.width,
                    height:img.height,
                    file: file,
                    x: 10,
                    y:40,
                    opacity: 1, contrast: 100,
                    alt: ''
                  };
                setPreviewImage(newImage);
                setPreviewImages((prevImages) => {
                    const updatedImages = [...prevImages, newImage];
                    index=prevImages.length
                   console.log('length',index)
                   setSelectedIndex(index)
                    return updatedImages;
                  });
                
            };
            img.src = reader.result;
        };
       
    };


    const handleWidthChange = (event, newValue) => {
        // setPreviewImage((prevImage) => ({
        //     ...prevImage,
        //     width: newValue,
        // }));

        setPreviewImages(prevStateArray => {
            const newArray = [...prevStateArray];
            newArray[selectedIndex]['width'] = newValue;
            return newArray;
          });
    };

    const handleHeightChange = (event, newValue) => {
        setPreviewImages(prevStateArray => {
            const newArray = [...prevStateArray];
            newArray[selectedIndex]['height'] = newValue;
            return newArray;
          });
    };

    const handleXChange = (event, value) => {

        const element = document.getElementById('previewImg');
       
        const containerWidth = element.offsetParent.clientWidth;
        const containerHeight = element.offsetParent.clientHeight;
        
        console.log('widthhhhhhhhhhhh',containerWidth)
       
       
        // setPreviewImage((prevImage) => ({
        //     ...prevImage,
        //     x: value,
        // }));

        setPreviewImages(prevStateArray => {
            const newArray = [...prevStateArray];
            newArray[selectedIndex]['x'] = value;
            return newArray;
          });
    };

    const handleYChange = (event, value) => {
        const element = document.getElementById('previewImg');
        const containerWidth = element.offsetParent.clientWidth;
        const containerHeight = element.offsetParent.clientHeight;
        console.log('heughttttt',containerHeight)
       
       
        setPreviewImages(prevStateArray => {
            const newArray = [...prevStateArray];
            newArray[selectedIndex]['y'] = value;
            return newArray;
          });
    };

    const handleOpacityChange = (event, value) => {
        setPreviewImage((prevImage) => ({
            ...prevImage,
            opacity: value,
        }));
    };

    const handleContrastChange = (event, value) => {
        setPreviewImage((prevImage) => ({
            ...prevImage,
            contrast: value,
        }));
    };
    
    const onClickDimension=()=>{
        console.log('width',previewImages[selectedIndex].width)
        console.log('height',previewImages[selectedIndex].height)
        setControls(prevControls=>({
            ...prevControls,
            dimension:true,
            position: false,
            contrast: false,
            opacity:false,
        }))
    }
    const onClickOpacity=()=>{
        setControls(prevControls=>({
            ...prevControls,
            opacity:true,
            dimension: false,
            position: false,
            contrast: false
        }))
    }
    const onClickPosition=()=>{
        console.log('x',selectedIndex)
        console.log('y',previewImages[selectedIndex].y)
        console.log((boxDimensions.width))
        setControls(prevControls=>({
            ...prevControls,
            dimension:false,
            position: true,
            contrast: false,
            opacity:false,
        }))
    }

    const onClicContrast=()=>{
        setControls(prevControls=>({
            ...prevControls,
            dimension:false,
            position: false,
            contrast: true,
            opacity:false,
        }))
    }

    const onUpload=()=>{
        setPreviewMode(false)
        // console.log('width',Math.round(previewImage.width *(1920/boxDimensions.width)))
        // console.log('height',Math.round(previewImage.height*(1080/boxDimensions.height)))
        // console.log('x',Math.round(previewImage.x *(1920/boxDimensions.width)))
        // console.log('y',Math.round(previewImage.y*(1080/boxDimensions.height)))
        // console.log('uid',uid)
        // console.log('slotid',slotId)
        // console.log('filetype',previewImage.file.type)


        // axios.get(mediaUrl + "/image/url", {
        //     params: {
        //       user_id: uid,
        //       slot_id: slotId,
        //       width:Math.round(previewImage.width *(1920/boxDimensions.width)),
        //       height:Math.round(previewImage.height*(1080/boxDimensions.height)),
        //       x:Math.round(previewImage.x *(1920/boxDimensions.width)),
        //       y:Math.round(previewImage.y*(1080/boxDimensions.height))
        //     }
        //   }).then((res) => {
        //     console.log('res',res.data);
        //     console.log('signedurl',res.data.signed_url);
           
        //     uploadLogoToPreSignedUrl(res.data.signed_url,previewImage)


        //   });

    }

    const uploadLogoToPreSignedUrl = (preSignedUrl, imageFile) => {
        const headers = {
          'Content-Type': imageFile.file.type,
          'Content-Disposition': 'attachment'
        };
        return axios.put(preSignedUrl, imageFile.file, { headers: headers })
          .then(response => {
            console.log(response);
            
          })
          .catch(error => {
            console.log(error);
            
          });
      }
const handleGoBack=()=>{
    navigate(`/?user_id=${uid}&slot_id=${slotId}`);
}
const formatFileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };
  const handleRemovePreview = (index) => {
    console.log('handle remove');
    const updatedImages = [...previewImages];
    updatedImages.splice(index, 1);
    setPreviewImages(updatedImages);
  };

 const uploadLogos=async ()=>{
    // setLoadingBar(true);

    for (let i = 0; i < previewImages.length; i++) {
      const image = previewImages[i];
      const data = {
        slotId: slotId,
        userId: uid,
        width: Math.round(image.width * 1920 / 550),
        height: Math.round(image.height * 1080 / 308.125),
        x: Math.round(image.x * 1920 / 550),
        y: Math.round(image.y * 1080 / 308.125),
      };

      try {
        await uploadLogo(data, image.file);
        console.log('Upload completed for logo:');
      } catch (error) {
        console.error('Upload failed for logo:');
        console.error(error);
      }
    }

    setPreviewImages([]);
    // setLogoUploaded(true);
    // setLoadingBar(false);

  }
  const uploadLogo = async (data, imageFile) => {
    const url = `${process.env.REACT_APP_URL_SCP}/image/url?user_id=${data.userId}&slot_id=${data.slotId}&width=${data.width}&height=${data.height}&x=${data.x}&y=${data.y}`;

    try {
      const getResponse = await axios.get(url, { responseType: 'json' });
      const preSignedUrl = getResponse.data.signed_url;

      const putResponse = await axios.put(preSignedUrl, imageFile, {
        headers: {
          'Content-Type': imageFile.type,
          'Content-Disposition': 'attachment',
        },
      });

      return putResponse.data;
    } catch (error) {
      throw error;
    }
  };
    return (
        <div className={styles.container}>
            <div className="d-flex flex-row justify-content-center" style={{ width: '100%' }}>
                <ReactPlayer width="100%" height='100%'
                    controls={true}
                    playing
                    playIcon={<button>Play</button>}
                    url='https://youtu.be/CvHzWF_iTLo' />
            </div>
            {!previewMode && <>


                <div className={styles.icon_container}>
                    <button className={styles.back_button} onClick={handleGoBack}>Back</button>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img className={styles.upload_icon} src={AdvertIcon} />
                        <p className={styles.tittle}>Images</p>
                    </div>
                </div>

                {/* <p className={styles.tittle}>Advert</p> */}

                <p className={styles.upload_label}>Upload your File</p>
                <img className={styles.upload_icon} src={uploadIcon} />
                <button className={styles.browse_button} onClick={handleFileSelect}>Browse</button>

               {previewImages?.map((image, index) => ( <div className={styles.preview_list}>
                  <div className={styles.list_title}>
                   <p style={{color:'black'}}>{image.file.name} </p>
                   <p style={{color:'black'}}> ({formatFileSize(image.file.size)})</p>
                   </div>
                  
                   <Button onClick={() => handleRemovePreview(index)} className={styles.remove_button}>
                    X
                   </Button>
                   
                </div>))
                }
               
                <button className={styles.upload_button}onClick={uploadLogos}>Upload</button>
            </>
            }
            {previewMode && <div className={styles.preview}>
                <div className={styles.preview_cont}>
                    <div className={styles.icons}>
                        <div className={styles.icon}>
                           {controls.dimension==true?<img className={styles.logo} src={WidthIcon} onClick={onClickDimension}/>:<img className={styles.logo} src={WidthIconPassive} onClick={onClickDimension}/>}

                        </div>
                       
                        <div className={styles.icon}>
                           
                            {controls.position==true?<img className={styles.logo} src={positionIcon} onClick={onClickPosition}/>:<img className={styles.logo} src={positionIconPassive} onClick={onClickPosition}/>}
                        </div >
                        <div className={styles.icon}>
                           
                            {controls.contrast==true?<img className={styles.logo} src={ContrastIcon} onClick={onClicContrast}/>:<img className={styles.logo} src={ContrastIconPassive} onClick={onClicContrast}/>}
                        </div>

                        <div className={styles.icon}>
                           
                            {controls.opacity==true?<img className={styles.logo} src={OpacityIcon} onClick={onClickOpacity}/>:<img className={styles.logo} src={OpacityIconPassive} onClick={onClickOpacity}/>}

                        </div>

                    </div>


                   
  <div id="previewImg" ref={boxRef} className={styles.preview_container}>
    {previewImages.map((image, index) => (
      <img
        key={index}
        src={image.src}
        alt="Selected image"
        style={{
          width: `${image.width}px`,
          height: `${image.height}px`,
          position: 'absolute',
          left: `${image.x}px`,
          top: `${image.y}px`,
          opacity: `${image.opacity}`,
          contrast: `${image.contrast}`,
          filter: `contrast(${image.contrast}%)`,
        }}
      />
    ))}
  </div>


                </div>

                {controls.dimension&&<div className={styles.control_icon}>
                    <img className={styles.logo} src={WidthIconSmall} />
                    <p style={{color:'black'}}>{Math.round(previewImages[selectedIndex].width *(1920/boxDimensions.width))}</p>

                    <Slider
                        value={previewImages[selectedIndex].width}
                        min={0}
                        max={500}
                        step={1}
                        onChange={handleWidthChange}
                    />
                </div>}

                {controls.dimension&&<div className={styles.control_icon}>
                    <img className={styles.logo} src={HeightIconSmall} />
                    <p style={{color:'black'}}>{Math.round(previewImages[selectedIndex].height*(1080/boxDimensions.height))}</p>
                    <Slider
                        value={previewImages[selectedIndex].height}
                        min={0}
                        max={500}
                        step={1}
                        onChange={handleHeightChange}
                    />
                </div>}

                {controls.opacity&&<div className={styles.control_icon}>
                    <img className={styles.logo} src={OpacityIconSmall} />
                    <Slider
                        value={previewImages[selectedIndex].opacity}
                        min={0}
                        max={1}
                        step={.1}
                        onChange={handleOpacityChange}
                    />
                </div>}

                {controls.contrast&&<div className={styles.control_icon}>
                    <img className={styles.logo} src={ContrastIconSmall} />

                    <Slider
                        value={previewImages[selectedIndex].contrast}
                        min={0}
                        max={200}
                        step={1}
                        onChange={handleContrastChange}
                    />
                </div>}

                {controls.position&&<div className={styles.control_icon}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginRight:'4px',}}>
                    <img className={styles.logo} src={PositionIconSmall} />
                    <p style={{color:'black'}}>{Math.round(previewImages[selectedIndex].x *(1920/boxDimensions.width))}</p>
                   </div>
                    <Slider 
                        defaultValue={0} 
                        value={previewImages[selectedIndex].x} 
                        onChange={handleXChange} 
                        min={0}
                        max={boxDimensions.width - previewImage.width} 
                    />

                </div>}

                {controls.position&&<div className={styles.control_icon}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginRight:'4px'}}>
                    <img className={styles.logo} src={PositionIconSmall} />
                    <p style={{color:'black'}}>{Math.round(previewImages[selectedIndex].y*(1080/boxDimensions.height))}</p>
                    </div>
                    <Slider defaultValue={0} 
                        value={previewImages[selectedIndex].y} 
                        onChange={handleYChange} 
                        min={0}
                        max={boxDimensions.height -previewImage.height} 
                    />
                </div>}
<div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}> <button className={styles.upload_button}onClick={onUpload}>Done</button> </div>
                
            </div>}

        </div>
    )
}

export default Images