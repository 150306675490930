import React,{useState} from 'react';
import styles from './Advert.module.css'
import uploadIcon from '../../assets/upload_icon.png'
import AdvertIcon from '../../assets/advert_icon.png'
import { useLocation } from "react-router";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


import ReactPlayer from 'react-player'



const Advert = () => {
    const search = useLocation().search;
    const navigate = useNavigate();
  const uid = new URLSearchParams(search).get("user_id");
  const slotId = new URLSearchParams(search).get("slot_id");
  let mediaUrl= process.env.REACT_APP_URL_SCP;
    const [previewVideo, setPreviewVideo] = useState({ src: '', file: null });
    const [videoUploaded, setVideoUploaded] = useState(true);
    const handleFileSelect = () => {
        const fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'video/mp4');
        fileInput.addEventListener('change', handleFileUpload);
        fileInput.click();
        setVideoUploaded(true);
      };
      
      const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setPreviewVideo((prevVideo) => ({
            ...prevVideo,
            src: reader.result,
            file: file
          }));
        };
        setVideoUploaded(false);
      };
      const onUploadAdd=()=>{

       
        console.log('uid',uid)
        console.log('slotid',slotId)
        console.log('filetype',previewVideo.file.type)


        axios.get(mediaUrl + "/mp4/url", {
            params: {
              user_id: uid,
              slot_id: slotId,
            }
          }).then((res) => {
            console.log('res',res.data);
            console.log('signedurl',res.data.signed_url);
           
            uploadAddToPreSignedUrl(res.data.signed_url,previewVideo)


          });

      }


      const uploadAddToPreSignedUrl = (preSignedUrl, videoFile) => {
        const headers = {
          'Content-Type': videoFile.file.type,
          'Content-Disposition': 'attachment'
        };
        return axios.put(preSignedUrl, videoFile.file, { headers: headers })
          .then(response => {
            console.log(response);
            
          })
          .catch(error => {
            console.log(error);
            
          });
      }

      const onGoBack=()=>{
        navigate(`/?user_id=${uid}&slot_id=${slotId}`);
      }
    return(<div className={styles.container}>
      
        {previewVideo.src && (
            <div className={styles.preview_container}>
            <video width="320" height="240" controls>
            <source src={previewVideo.src} type="video/mp4" />
            Your browser does not support the video tag.
            </video>
            <button style={{marginTop:"20px"}} onClick={onUploadAdd}>Upload Video</button>
            </div>
        )}
        
       
       
        {videoUploaded &&  (
            <>
            <div className="d-flex flex-row justify-content-center" style={{width:'100%'}}>
                <ReactPlayer width="100%"  height='100%'
                    controls={true}
                    playing
                    playIcon={<button>Play</button>}
                    url='https://youtu.be/CvHzWF_iTLo' />
            </div>  
           
            <div className={styles.icon_container}>
                <button className={styles.back_button} onClick={onGoBack}>Back</button>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <img className={styles.upload_icon} src={AdvertIcon}/>
                  <p className={styles.tittle}>Advert</p>
                </div>
            </div> 
           
                {/* <p className={styles.tittle}>Advert</p> */}
        
           <p className={styles.upload_label}>Upload your File</p>
           <img className={styles.upload_icon} src={uploadIcon}/>
           <button className={styles.browse_button} onClick={handleFileSelect}>Browse</button>
           <p className={styles.urllabel_label}>Paste URL to Upload</p>
           <input className={styles.url_input} type="text"  />
           <button className={styles.upload_button}>Upload</button>
           </>
           )}
       
        </div>
    )
}

export default Advert